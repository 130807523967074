exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-category-featured-tsx": () => import("./../../../src/pages/category/featured.tsx" /* webpackChunkName: "component---src-pages-category-featured-tsx" */),
  "component---src-pages-category-finance-tsx": () => import("./../../../src/pages/category/finance.tsx" /* webpackChunkName: "component---src-pages-category-finance-tsx" */),
  "component---src-pages-category-investments-tsx": () => import("./../../../src/pages/category/investments.tsx" /* webpackChunkName: "component---src-pages-category-investments-tsx" */),
  "component---src-pages-category-taxes-tsx": () => import("./../../../src/pages/category/taxes.tsx" /* webpackChunkName: "component---src-pages-category-taxes-tsx" */),
  "component---src-pages-consultation-tsx": () => import("./../../../src/pages/consultation.tsx" /* webpackChunkName: "component---src-pages-consultation-tsx" */),
  "component---src-pages-disclosures-tsx": () => import("./../../../src/pages/disclosures.tsx" /* webpackChunkName: "component---src-pages-disclosures-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investment-management-tsx": () => import("./../../../src/pages/investment-management.tsx" /* webpackChunkName: "component---src-pages-investment-management-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-retirement-planning-tsx": () => import("./../../../src/pages/retirement-planning.tsx" /* webpackChunkName: "component---src-pages-retirement-planning-tsx" */),
  "component---src-pages-tax-planning-tsx": () => import("./../../../src/pages/tax-planning.tsx" /* webpackChunkName: "component---src-pages-tax-planning-tsx" */),
  "component---src-templates-blog-article-template-tsx": () => import("./../../../src/templates/BlogArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-article-template-tsx" */),
  "component---src-templates-tag-page-template-tsx": () => import("./../../../src/templates/TagPageTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-page-template-tsx" */)
}

